// TODO generate this config at build time by reading values from from env variables or buildspec
// calling out to the orsnn-app-api-demo stack to get the values
const config = {
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://9yzjry10dg.execute-api.us-west-2.amazonaws.com/prod/graphql',
  },
  logs: {
    REGION: 'us-west-2',
    URL: 'https://9yzjry10dg.execute-api.us-west-2.amazonaws.com/prod/log',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_T9df2HGSD',
    APP_CLIENT_ID: '6qfvsahur3oja447aso4t83e46',
    IDENTITY_POOL_ID: 'us-west-2:d8f0d044-b49f-43fc-af6d-84c533a05f10',
  },
  fileGateway: {
    REGION: 'us-west-2',
    URL: 'https://9yzjry10dg.execute-api.us-west-2.amazonaws.com/prod/files',
  },
  smartHeaderGateway: {
    REGION: 'us-west-2',
    URL: 'https://9yzjry10dg.execute-api.us-west-2.amazonaws.com/prod/smart_header_mapping',
  },
  website: {
    URL: 'https://demo.orsnn.com',
  },
  pinpoint: {
    REGION: 'us-west-2',
    APP_ID: '67e23a9191c64ba2be31126c2057c88b',
  },
  features: {},
} as const;

export default config;
